import { Auth0Provider } from '@auth0/auth0-react'
import ErrorPageNext from 'next/error'
import Head from 'next/head'
import { useRouter } from 'next/router'
import React, { ReactNode, useCallback, useMemo } from 'react'
import { CookiesProvider } from 'react-cookie'
import { Link } from '@scentregroup/shared/link'
import { RecoilRoot } from 'recoil'

import { HubProvider } from '@hub/design-system-base'
import FeatureFlagsProvider, {
  useFeatureFlagOverridesFromQuery,
} from '@scentregroup/shared/components/feature-flags-provider'
import { NATIONAL_CONFIG } from '@scentregroup/shared/config'
import { resolveCountrySpecificValue } from '@scentregroup/shared/configuration'
import { RawCookieName } from '@scentregroup/shared/constants'
import { GetHrefContext, LinkContext } from '@scentregroup/shared/context'
import { Country, CountryProvider } from '@scentregroup/shared/country'
import useIsMounted from '@scentregroup/shared/hooks/use-is-mounted'
import GoogleTagManager, {
  GoogleTagManagerData,
} from '@scentregroup/shared/hub-components/google-tag-manager'
import RouteChangeIndicator from '@scentregroup/shared/hub-components/route-change-indicator'
import {
  SessionIdProvider,
  CookieSessionIdStorage,
} from '@scentregroup/shared/session-id'
import { Spinner } from '@hub/spinner'
import '@scentregroup/ui/fonts/index.css'
import '@scentregroup/ui/tools/reset.css'

import { ApolloProvider } from '../components/apollo-provider'
import { ErrorBoundary } from 'react-error-boundary'
import { AppStateProvider } from '../components/state-provider'
import { getSharedEnv, isCookieEnabledBrowser } from '../lib/env/shared/env'
import { staticSharedEnv } from '../lib/env/shared/shared-env/shared-env'
import HttpStatusCode from '../lib/http-codes'
import { SiteConfigurationContext } from '../lib/site-configuration'
import { DialogProvider } from '../lib/use-dialog'
import { NotificationProvider } from '../lib/use-notification'
import { IMarketplaceSiteConfiguration } from '../types/site-configuration'

import '../styles/globals.scss'
import ErrorPage from '../components/error-page'
import { AnalyticsProvidersAndInit } from '@scentregroup/shared/analytics'
import BasicPageQueryProvider from '@scentregroup/shared/basic-page'
import { MagicLinkProvider } from '@scentregroup/shared/hub-components/magic-link'

function makeMarketplaceSiteConfiguration(): IMarketplaceSiteConfiguration {
  return {
    urls: {
      faq: getSharedEnv('urls.faq'),
      termsAndConditions: getSharedEnv('urls.termsAndConditions'),
      directReturnPolicy: getSharedEnv('urls.directReturnPolicy'),
    },
  }
}

const CookiesWrapper: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const isBrowser = useIsMounted()

  return isBrowser && !isCookieEnabledBrowser() ? (
    <ErrorPageNext
      statusCode={HttpStatusCode.BAD_REQUEST}
      title="Cookies are disabled  :(  Please enable cookies and try again"
    ></ErrorPageNext>
  ) : (
    <CookiesProvider>{children}</CookiesProvider>
  )
}

const cookieSessionIdStorage = new CookieSessionIdStorage({
  cookieName: RawCookieName.SESSION_ID,
  expiryInDays: 8,
})

interface IAppProps<P> {
  Component: React.FunctionComponent<React.PropsWithChildren<unknown>>
  pageProps: P
}

function App<P extends { children?: ReactNode }>({
  Component,
  pageProps,
}: IAppProps<P>): React.ReactElement<IAppProps<P>> {
  const router = useRouter()

  const country: Country =
    router.locale === 'en-NZ' ? 'New Zealand' : 'Australia'
  const siteConfiguration = {
    country,
    privacyPolicyUrl: resolveCountrySpecificValue(
      staticSharedEnv.urls.privacyPolicy,
      country
    ),
    marketplace: makeMarketplaceSiteConfiguration(),
  }

  const META_INFORMATION =
    router.locale === 'en-NZ' ? NATIONAL_CONFIG.nz : NATIONAL_CONFIG.au

  const isBrowser = useIsMounted()

  const getHref = useCallback(
    () =>
      (isBrowser && window.location.href) ||
      `${META_INFORMATION.url}${router.basePath}${router.asPath}`,
    [META_INFORMATION, router.asPath, router.basePath, isBrowser]
  )

  const auth0RedirectUri = useMemo(() => {
    const baseUri = isBrowser ? window.location.origin : META_INFORMATION.url
    return baseUri ? `${baseUri}/account/callback` : undefined
  }, [isBrowser, META_INFORMATION.url])

  const featureFlagOverrides = useFeatureFlagOverridesFromQuery(router.query)
  return (
    <>
      <Head>
        <link rel="preconnect" href="https://images.scentregroup.io" />
        {Object.keys(router.query).length > 0 && (
          <meta name="robots" content="noindex" />
        )}
        <base href="/account" />
      </Head>
      <GoogleTagManager
        containerId={process.env.NEXT_PUBLIC_GTM_ID}
        preview={process.env.NEXT_PUBLIC_GTM_PREVIEW_ENV}
        auth={process.env.NEXT_PUBLIC_GTM_PREVIEW_AUTH}
      />
      <GoogleTagManagerData />
      <LinkContext.Provider value={Link}>
        <HubProvider Link={Link}>
          <SessionIdProvider storage={cookieSessionIdStorage}>
            <CountryProvider country={country}>
              <Auth0Provider
                domain={process.env.NEXT_PUBLIC_AUTH0_DOMAIN ?? ''}
                clientId={process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID ?? ''}
                authorizationParams={{
                  redirect_uri: auth0RedirectUri,
                }}
              >
                <ApolloProvider>
                  <SiteConfigurationContext.Provider value={siteConfiguration}>
                    <CookiesWrapper>
                      <FeatureFlagsProvider
                        doGetFromEnv
                        overrides={featureFlagOverrides}
                      >
                        <AnalyticsProvidersAndInit>
                          <GetHrefContext.Provider value={getHref}>
                            <RecoilRoot>
                              <AppStateProvider>
                                <BasicPageQueryProvider>
                                  <RouteChangeIndicator
                                    fallback={() => (
                                      <div
                                        style={{
                                          width: '100%',
                                          height: '100%',
                                        }}
                                      >
                                        <Spinner
                                          variant="large"
                                          isSeasonal={false}
                                        />
                                      </div>
                                    )}
                                  >
                                    <NotificationProvider>
                                      <DialogProvider>
                                        <ErrorBoundary
                                          fallbackRender={({ error }) => (
                                            <ErrorPage error={error} />
                                          )}
                                        >
                                          <MagicLinkProvider>
                                            <Component {...pageProps} />
                                          </MagicLinkProvider>
                                        </ErrorBoundary>
                                      </DialogProvider>
                                    </NotificationProvider>
                                  </RouteChangeIndicator>
                                </BasicPageQueryProvider>
                              </AppStateProvider>
                            </RecoilRoot>
                          </GetHrefContext.Provider>
                        </AnalyticsProvidersAndInit>
                      </FeatureFlagsProvider>
                    </CookiesWrapper>
                  </SiteConfigurationContext.Provider>
                </ApolloProvider>
              </Auth0Provider>
            </CountryProvider>
          </SessionIdProvider>
        </HubProvider>
      </LinkContext.Provider>
    </>
  )
}

export default App
