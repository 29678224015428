import qs from 'query-string';
import { useRouter } from '../router';
function useLocation() {
  const nextRouter = useRouter();
  const queryString = qs.stringify(nextRouter.query);
  return {
    search: queryString ? "?".concat(queryString) : '',
    pathname: nextRouter.asPath
  };
}
export { useLocation };