import * as Sentry from '@sentry/nextjs'

import { NotificationType } from '../types/notification'

export const onError = (
  error: Error,
  notificationFn?: (
    message: string,
    type?: NotificationType | undefined
  ) => void,
  userMessage?: string
): void => {
  console.dir(error, { depth: null })
  Sentry.captureException(error)
  if (notificationFn) {
    notificationFn(userMessage || error.message, NotificationType.ERROR)
  }
}
