import { useEffect, useState } from 'react';
import { pick, sortBy } from 'lodash';
const useMemoizedPick = (obj, keys) => {
  const {
    0: picked,
    1: setPicked
  } = useState(pick(obj, sortBy(keys)));
  const newPicked = pick(obj, sortBy(keys));
  useEffect(() => {
    setPicked(newPicked);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, Object.values(newPicked));
  return picked;
};
export default useMemoizedPick;