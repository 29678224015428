import React from 'react'
import {
  Modal as ChakraModal,
  ModalOverlay as ChakraModalOverlay,
  ModalContent as ChakraModalContent,
  ModalHeader as ChakraModalHeader,
  ModalCloseButton as ChakraModalCloseButton,
  ModalBody as ChakraModalBody,
  ModalFooter as ChakraModalFooter,
} from '@chakra-ui/react'
import { Button } from '@hub/button'
import { Box } from '@hub/box'
import {
  As,
  HubStyleObject,
  forwardRef,
  injectTheme,
} from '@hub/design-system-base'
import { modalTheme } from './theme'

injectTheme('Modal', modalTheme)

type ModalAction = {
  label: string
  onClick: () => void
}

type ModalProps = {
  isOpen: boolean
  heading: string
  action: ModalAction
  onClose: () => void
  children: React.ReactNode
  closeOnOverlayClick?: boolean
  dataTestId?: string
  sx?: HubStyleObject
  as?: As
  className?: string
}

export const Modal = forwardRef<ModalProps, typeof ChakraModal>(
  (
    {
      isOpen,
      heading,
      action,
      onClose,
      children,
      closeOnOverlayClick = true,
      dataTestId = 'alert',
      sx,
      as,
      className,
      ...props
    },
    ref
  ) => {
    return (
      <ChakraModal
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={closeOnOverlayClick}
        {...props}
      >
        <Box
          ref={ref}
          sx={sx}
          as={as}
          className={className}
          data-test-id={`modal-${dataTestId}`}
        >
          <ChakraModalOverlay />
          <ChakraModalContent
            top={'15%'}
            maxWidth={'512px'}
            paddingY={'spacing-lg'}
            paddingX={'spacing-xl'}
          >
            <ChakraModalHeader>{heading}</ChakraModalHeader>
            <ChakraModalCloseButton margin={'spacing-sm'} />
            <Box sx={{ borderBottom: '1px solid', borderBottomColor: 'ghost' }} />
            <ChakraModalBody>{children}</ChakraModalBody>
            <ChakraModalFooter>
              <Button
                colorScheme={'licorice'}
                variant={'solid'}
                size={'md'}
                textAlign={'center'}
                type={'button'}
                width={'100%'}
                onClick={() => {
                  action.onClick()
                }}
              >
                {action.label}
              </Button>
            </ChakraModalFooter>
          </ChakraModalContent>
        </Box>
      </ChakraModal>
    )
  }
)
